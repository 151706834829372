import React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class Service extends React.Component {
    render() {
        return (
            <Layout className="solid">
                <HelmetDatoCms>
                    <title>Falve - Error 404</title>
                    <meta name="description" content="Page not found"/>
                </HelmetDatoCms>
                <div className="main-error">
                    <p>404</p>
                    <span>Przepraszamy<br/> Strona o podanym adresie nie istnieje</span>
                    <Link to="/" className="btn btn--yellow">Powrt do strony głównej</Link>
                </div>
            </Layout>
        )
    }
}
